import {mapGetters} from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import MembershipApiService from "@/core/services/api.service.membership";
import ApiService from "@/core/services/api.service";

export default {
    props: {
        modal: {
            default: false
        },
        value: Boolean
    },

    name: "package",
    data() {
        return {
            valid: false,
            isSaving: false,
            packageId: null,
            membershipApiInitiated: false,
            selectedInstallmentDuration: null,
            installmentDurations: ["MONTHLY", "YEARLY"],
            permissions: [],
            permissionsAreLoading: false,
            permissionsSearchInput: null,
            translationsAreLoading: false,
            base64Image: null,
            savedImagePath: null,
            infoEng: null,
            infoMne: null,
            infoGer: null,
            pdv: 0,
            memberShipPackage: {
                name: "",
                description: "",
                catalogItemPrices: [],
                active: true,
                installmentDurationInDays: null,
                minNumberOfInstallments: 0,
                numberOfFreeMonths: 0,
                oobleeCoins: 0,
                advertisementOobleeCoins: 0,
                fee: 0,
                upsellLevel: 0,
                permissions: [],
                imagePath: null,
                isStarter: false,
                catalogItemTranslations: [],
                price: 0,
                priceWithoutVAT: 0
            },
            requiredRule: [v => !!v || "Field is required"]
        };
    },
    async created() {
        await MembershipApiService.init();
        this.membershipApiInitiated = true;

        this.searchPermissions();
        this.packageId = this.$route.params.packageId;
        let title =
            this.packageId != null
                ? "Package details"
                : "Insert new package";
        this.$store.dispatch(SET_BREADCRUMB, [{title: title}]);

        ApiService.get(`api/url/get-pdv`)
            .then(response => {
                this.$log.debug("PDV: ", response.data);
                this.pdv = response.data;
            })
            .catch(error => {
                this.$log.error("Error: ", error);
                this.errored = true;
            })
            .finally(() => (this.loading = false));


        if (this.packageId != null) {
            MembershipApiService.get(`/CatalogItems/GetBundleById`, `${this.packageId}`)
                .then(response => {
                    this.$log.debug("Package: ", response.data);
                    this.memberShipPackage = response.data;
                    this.memberShipPackage.price = (response.data != null && response.data.catalogItemPrices.length > 0 ? response.data.catalogItemPrices[0].price : 0);
                    if (this.memberShipPackage.installmentDurationInDays === 365) this.selectedInstallmentDuration = "YEARLY";
                    if (this.memberShipPackage.installmentDurationInDays === 30) this.selectedInstallmentDuration = "MONTHLY";

                    let existingEngTrans = this.memberShipPackage.catalogItemTranslations.filter(obj => {
                        return obj.locale === "ENG";
                    })[0]
                    if (existingEngTrans != null) {
                        this.infoEng = existingEngTrans.info;
                    }

                    let existingMneTrans = this.memberShipPackage.catalogItemTranslations.filter(obj => {
                        return obj.locale === "MNE";
                    })[0]
                    if (existingMneTrans != null) {
                        this.infoMne = existingMneTrans.info;
                    }

                    let existingGerTrans = this.memberShipPackage.catalogItemTranslations.filter(obj => {
                        return obj.locale === "GER";
                    })[0]
                    if (existingGerTrans != null) {
                        this.infoGer = existingGerTrans.info;
                    }
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.loading = false));
        }
    },
    methods: {
        endsWith(suffix, str) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        },
        paddingSize(value) {
            if (this.endsWith("==", value)) {
                return 2;
            }
            if (this.endsWith("=", value)) {
                return 1;
            }
            return 0;
        },
        size(value) {
            return (value.length / 4) * 3 - this.paddingSize(value);
        },
        byteSize(base64String) {
            return this.formatAsBytes(this.size(base64String));
        },
        formatAsBytes(size) {
            return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " bytes";
        },
        handleImage(event) {
            if (event && event.target.files && event.target.files[0]) {
                const file = event.target.files[0];
                this.toBase64(file, base64Data => {
                    this.base64Image = base64Data;
                });
            }
        },
        toBase64(file, cb) {
            const fileReader = new FileReader();
            fileReader.onload = e => {
                const base64Data = e.target.result;
                cb(base64Data);
            };
            fileReader.readAsDataURL(file);
        },
        remove(item) {
            console.log(this.memberShipPackage);
            console.log(item.id);
            console.log(this.memberShipPackage.permissions);
            if (this.memberShipPackage.permissions.length != 0 && typeof this.memberShipPackage.permissions[0] === 'string') {

                const index = this.memberShipPackage.permissions.indexOf(item.id);
                if (index >= 0) this.memberShipPackage.permissions.splice(index, 1);
            } else {
                const index = this.memberShipPackage.permissions.map(function (e) {
                    return e.id;
                }).indexOf(item.id);
                console.log(index);
                if (index >= 0)
                    this.memberShipPackage.permissions.splice(index, 1);
            }


        },
        searchPermissions() {
            // Items have already been loaded
            if (this.permissions.length > 0) return;

            // Items have already been requested
            if (this.permissionsAreLoading) return;
            let params = {};
            this.permissionsAreLoading = true;
            MembershipApiService.query("/CatalogItems/GetPermissionDefinitions",
                params
            ).then(response => {
                // JSON responses are automatically parsed.
                this.$log.debug("package permissions response: ", response.data);
                this.permissions = response.data;
                this.loading = false;
            })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.permissionsAreLoading = false));
        },

        previousState() {
            this.$router.go(-1);
        },
        async validateAndSave() {
            if (this.$refs.form.validate()) {
                await this.uploadImage();
                this.savePackage(this.memberShipPackage);
            }
        },
        uploadImage() {
            if (this.base64Image == null || this.base64Image === "") return;
            this.loading = true;
            return new Promise(resolve => {
                this.loading = true;
                let imageDTO = {};
                imageDTO['base64Image'] = this.base64Image;

                ApiService.post("api/image-upload", imageDTO).then(response => {
                    this.$log.debug("Image uploaded: " + response.data);
                    this.savedImagePath = response.data;
                    this.memberShipPackage.imagePath = this.savedImagePath;
                    resolve();
                })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.savedImagePath = null;
                    })
                    .finally(() => {
                        this.loading = false;
                        return this.savedImagePath;
                    });
            });
        },
        saveTranslations(membershipPackage) {
            let translationsForRequest = [];

            let translationEng = {};
            let existingEngTrans = membershipPackage.catalogItemTranslations.filter(obj => {
                return obj.locale === "ENG";
            })[0]
            if (existingEngTrans != null) translationEng['id'] = existingEngTrans.id;
            translationEng['locale'] = "ENG";
            translationEng['info'] = this.infoEng;
            translationEng['catalogItemId'] = membershipPackage.id;
            if (this.infoEng) translationsForRequest.push(translationEng);

            let translationMne = {};
            let existingMneTrans = membershipPackage.catalogItemTranslations.filter(obj => {
                return obj.locale === "MNE";
            })[0]
            if (existingMneTrans != null) translationEng['id'] = existingMneTrans.id;
            translationMne['locale'] = "MNE";
            translationMne['info'] = this.infoMne;
            translationMne['catalogItemId'] = membershipPackage.id;
            if (this.infoMne) translationsForRequest.push(translationMne);

            let translationGer = {};
            let existingGerTrans = membershipPackage.catalogItemTranslations.filter(obj => {
                return obj.locale === "GER";
            })[0]
            if (existingGerTrans != null) translationGer['id'] = existingGerTrans.id;
            translationGer['locale'] = "GER";
            translationGer['info'] = this.infoGer;
            translationGer['catalogItemId'] = membershipPackage.id;
            if (this.infoGer) translationsForRequest.push(translationGer);

            console.log("translations: " + translationsForRequest);

            membershipPackage.catalogItemTranslations = translationsForRequest;

        },
        calculatePriceWithoutVAT: function (price) {
            return price * (1 - (this.pdv / 100));
        },
        savePackage(membershipPackage) {
            this.isSaving = true;
            this.loading = true;
            let catalogItemPrices = [];

            let catalogItemPrice = {};
            if (membershipPackage.catalogItemPrices != null && membershipPackage.catalogItemPrices.length > 0) {
                catalogItemPrice['id'] = membershipPackage.catalogItemPrices[0]['id'];
            }
            catalogItemPrice['price'] = this.memberShipPackage.price;
            catalogItemPrice['currency'] = "EUR";
            catalogItemPrices.push(catalogItemPrice);
            membershipPackage.catalogItemPrices = catalogItemPrices;

            if (this.selectedInstallmentDuration === "YEARLY") membershipPackage.installmentDurationInDays = 365;
            if (this.selectedInstallmentDuration === "MONTHLY") membershipPackage.installmentDurationInDays = 30;

            let permissions = [];
            if (membershipPackage.permissions != null && membershipPackage.permissions.length > 0) {
                membershipPackage.permissions.forEach(function (entry) {
                    if (typeof entry === 'string') {
                        let permission = {};
                        permission['id'] = entry;
                        permissions.push(permission);
                    } else {
                        permissions.push(entry);
                    }
                });
                membershipPackage.permissions = permissions;
            }

            this.$log.debug("PDV: " + this.pdv);
            membershipPackage.priceWithoutVAT = this.calculatePriceWithoutVAT(membershipPackage.price);
            this.$log.debug("Price withoutVAT: " + membershipPackage.priceWithoutVAT);


            this.saveTranslations(membershipPackage);
            if (membershipPackage.id != null) {
                MembershipApiService.post(`/CatalogItems/UpdateBundle`, membershipPackage)
                    .then(response => {
                        this.$log.debug("Package updated: " + response);
                        //this.saveTranslations(membershipPackage.id);
                    })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.errored = true;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.isSaving = false;
                        this.previousState();
                    });
            } else {
                MembershipApiService.post(`/CatalogItems/CreateBundle`, membershipPackage)
                    .then(response => {
                        this.$log.debug("Package created: " + response);
                        //this.saveTranslations(membershipPackage.id);
                    })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.errored = true;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.isSaving = false;
                        this.previousState();
                    });
            }
        }
    },
    computed: {
        ...mapGetters(["currentUserPersonalInfo"]),
        ...mapGetters(["currentUser"]),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.$emit("input", value);
                }
            }
        }
    }
};
